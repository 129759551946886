import React from "react"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import keywords from "../../constants/keywords"
import NewSignalsHero from "../../components/NewSignalsHero"
import ImageTitleDescriptionBullets from "../../components/ImageTitleDescriptionBullets"
import AdvancedAIImage from "../../assets/images/NewSignalServicesImages/Person-Standing-In-Middle-of-Projection.jpg"
import SpatialComputingImage from "../../assets/images/NewSignalServicesImages/AR-App-On-iPad.jpg"

// const LoadableParallax = loadable(() => import('../components/parallax/homeParallax'))

const NewSignalsPage = () => {

  // let keywords = keywords.home.keywords
  // console.log(keywords.home.keywords)
  return (
    <>

      <Layout black={false}>

        <SEO
          title={"New Signals | Differnt Systems"}
          description={"Predict outcomes, streamline operations, and foster innovation with advanced AI/ML solutions, while revolutionizing interactions through AR/VR/XR computing."}
          customKeywords={keywords.home.keywords}
        />
        <div className="offcanvas-wrap">
          <NewSignalsHero />
          <ImageTitleDescriptionBullets
            featureImage={AdvancedAIImage}
            title="Advanced AI/ML"
            description="Employ AI/ML to analyze extensive datasets within your business or client base that seamlessly integrate into existing or newly designed systems. Generate insightful strategies to optimize operations, streamline tasks, and capitalize on opportunities that align with your specific needs."
            bullet1="Use Historical Data to Predict Future Outcomes"
            bullet2="Create In-House Models That Are Privacy Focused"
            bullet3="Leverage Existing Infrastructure, such as Security Cameras"
            subTitle=""
          />
          <ImageTitleDescriptionBullets
            featureImage={SpatialComputingImage}
            title="Spacial Computing (AR, VR, XR)"
            description="Revolutionize user and staff interaction, streamline operation, foster collaboration, inspire innovation, and optimize data/application engagement in a boundless environment. Overlay information and insights, create entirely new environments, or establish a flexible workspace for innovation."
            bullet1="Provide Realistic Training Environments"
            bullet2="Visualize Products for Faster Iteration and Refinement"
            bullet3="Engage Customers Through Immersive Experiences"
            subTitle=""
            flip={true}
          />
          {/* <LoadableParallax /> */}
        </div >
      </Layout >

    </>
  )
}

export default NewSignalsPage